.remote-table-tabs {
  margin-bottom: 1.5em;
  //padding-left: .5em !important;
  border-bottom: 1px solid #dee2e6;
}

.remote-table-tabs-nav {
  margin-left: 1em;
}


.react-bootstrap-table-small {
  min-height: 100px !important;
}

.react-bootstrap-table {
  height: auto;
  position: relative;
  margin: auto;
  margin-bottom: 1rem;
  overflow: auto;
  min-height: 640px;

  table {
    width: 100%;
    min-height: 120px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }

  tbody {
    overflow-y: auto;
  }

  th,
  td {
    padding: 5px 10px;
    vertical-align: top !important;
  }

  thead {
    position: relative;
    z-index: 1;
    th {
      position: sticky;
      top: 0;
    }
  }

  .filter-label {
    margin-top: 0.5rem;
    display: block;
  }
}

._loading_overlay_overlay {
  min-height: 370px;
}

//.react-bootstrap-table th[data-row-selection] {
//  width: 36px !important;
//}

//.table-bordered td {
//  padding-left: 10px !important;
//}

.react-bootstrap-table td {
  vertical-align: middle !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}

.table-bordered {
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.react-bootstrap-table {
}