.react-data-grid-table {
  height: 100% !important;
}

.react-data-grid-updated-row {
  background-color: rgb(219, 236, 250) !important;
}

.rdg {
  border-radius: 2px;
  border: none !important;
}

.fill-grid.rdg-dark {
  // TODO harcode variable
  background-color: none !important;
  border: 1px solid #e5e5e5 !important;
  .rdg-row {
    color: black !important;
    font-weight: 400 !important;
    .rdg-editor-container {
      input {
        background-color: white !important;
        color: black !important;
      }
    }
  }
}

.rdg-row {
  background-color: white;
  .rdg-cell-dragged-over {
    background-color: rgb(219, 236, 250)  !important;
  }
}


//.rdg-cell[aria-readonly="true"] {
//  background-color: rgb(245, 245, 245)
//}

.rdg-cell {
  font-size: 13px;
  border-color: rgb(239, 242, 247);
  font-family: "Poppins", sans-serif;
  .cadd3bp700-beta12:hover {
    background-color: var(--rdg-selection-color) !important;
  }
}

.small-rdg-header {
  min-width: 10px;
}

.rdg-header-row {
  padding: 12px;
}

.rdg-header-row > .rdg-cell {
  font-weight: 700;
  background-color: rgb(239, 242, 247);
  color: rgb(73, 80, 87);
  font-family: "Poppins", sans-serif;
}

.rdg-checkbox-label {
  margin-bottom: 0px !important;
}
.rdg-checkbox {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  background-color: white !important;
  width: 15px;
  height: 15px;
  border-radius: 0.25rem;
  transition: all .1s linear;
}
.rdg-checkbox-input:checked+.rdg-checkbox {
  outline: none;
  background-color: #556ee6 !important;
  border: 1px solid #556ee6;
}