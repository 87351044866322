.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main.editorClassName {
  border: 1px solid #eeeef5;
  height: 200px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

//  Tien edit

div .btn-goback{
  display:inline;
}

.title-page{
  margin-bottom: 0px;
  display: inline;
}


#page-header-user-dropdown.header-item {
  height: auto;
}

.header-item img{
  width: 36px;
  height: 36px;
}

.dropdown-menu[data-popper-placement^=right] {
  bottom: -70px !important
}
#sidebar-menu{
  max-height: 515px;
  overflow: hidden scroll;
}

#sidebar-menu::-webkit-scrollbar, #sidebar-menu::-webkit-scrollbar {
  display: none;
}



.remote-table-tabs-nav {
  flex-wrap: nowrap;
}

.remote-table-tabs .simplebar-vertical{
  display: none;
}

.remote-table-tabs.row .remote-table-tabs-nav::-webkit-scrollbar, .remote-table-tabs.row .remote-table-tabs-nav::-webkit-scrollbar {
  display: none;
}

.profile-user{
  position: absolute;
    bottom: 0;
    left: 0;
    right: 0px;
    z-index: 9;
    width: 100%;
    div{
      width: 100%;
      #page-header-user-dropdown{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 24px 10px 10px;
        background: #fff;
        i.mdi-chevron-right {
          font-size: 16px;
          &:before {
            float: right
          }
        }
      }
      button.dropdown-item{
        padding: 8px 1.5rem
      }
    }
}

header{
  display: none
}
.page-content{
  padding-top: 24px
}



// popver react-bootstrap review custom 

.popover.popover-review-custom-data-grid{
  // background-color: #dfdfdf;
  z-index: 999 ;
  max-width: calc(100% - 298px) ;
  width: 100% ;
  margin-left: -10px;
  border: 2px solid #ccc;
}


@media (max-width: 992px) {
  header#page-topbar {
    display: block;
    left: 0
  }
  .page-content {
    padding: calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2);
  }

  .close-sidebar{
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0px;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }
  .popover.popover-review-custom-data-grid{
    max-width: calc(100% - 48px) ;
  }
}





@media (max-width: 767px) {
  // goback
  div .btn-goback{
    display:none ;
  }

  // .btn-group{
  //   display: none;
  // }
}


.react-bootstrap-table-pagination{
  padding-right: 0px;
  .react-bootstrap-table-pagination-list {
    padding-right: 0px;
  }
}

@media (max-width: 576px) {
  .title-page{
    margin-bottom: 24px;
    display: block;
  }


  .section-filter {
    flex-direction: column;
    form {
      .input-group{
        flex-direction: column;
        gap:1rem;
        input {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .react-bootstrap-table-pagination{
    justify-content: space-between;
    padding-right: 0px;
    div:first-child{
      width: auto;
    }
    .react-bootstrap-table-pagination-list {
      flex:1;
      padding-right: 0px;
    }
  }
}

.rdg-cell:hover {
  background-color: #f0ebfa;
}

.react-data-grid-normal-row {
  background-color: #ffffff !important;
}

.cellExpandClassname {
  /* needed on chrome */
  float: right;
  display: table;
  block-size: 100%;
  > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
  }
}

.normalLineHeight{
  line-height: normal;
}
.minHeightAuto {
  min-height: auto;
}

// tien custom input checkbox bulk edit fulfillment order
.rdg.RDGcustomCheckbox .rdg-header-row .rdg-checkbox-label{
  height: 35px;
}

.rdg.RDGcustomCheckbox .rdg-checkbox-label .rdg-checkbox-input {
  all: revert;
}

.rdg.RDGcustomCheckbox .rdg-checkbox-label .rdg-checkbox{
  display: none;
}

// 

.filter-cell {
  line-height: 35px;
    padding: 0;
    > div {
      padding-block: 0;
      padding-inline: 8px;
    }
}

.filterClassname {
  inline-size: 100%;
  padding: 4px;
  height: 25px;
}

// custom style input DatePicker
.inputDatePicker {
  height: 38px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 2px 8px;
}

.dropdownMenuUser{
  transform: translate(250px, -70px) !important;
}

.remote-table-product-template table tbody tr td {
  padding-top: 6px;
  padding-bottom: 6px;
}

.remote-table-product-template table tbody tr td .product-name:hover {
  text-decoration: underline !important;
}

// Nested dropdown
.dropdown-submenu-right{
  left: 100%;
  margin-top: -35px;
}

// custom style iframe view image
.iframeImage {
  width: 90%;
  height: 85%;
}

.draggable-table {
  border: 1px solid #e5e5e5;
  width: 100%;
  thead {
    background-color: #eff2f7;
  }
  tr {
    height: 40px;
    th {
      padding: 0 8px 0 8px;
    }
    td {
      color: black;
      border: 1px solid #e5e5e5;
      input {
        border: none;
      }
    }
  }
}


.mh-auto{
  min-height: auto !important;
}

// custom ReactGridGallery
.ReactGridGallery_custom-overlay {
  pointer-events: unset !important;
  .custom-overlay__caption {
    height: 100% !important;
    position: relative;
    .backdrop-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    .action-btns {
      position: absolute;
      z-index: 200;
    }
  }
}
.ReactGridGallery {
  .ReactGridGallery_tile-viewport {
    width: 226px !important;
    height: 226px !important;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
}

.vertical-menu{
  padding-bottom: 62px;
}

// custom styles for luckysheet
.luckysheet-input-box, .luckysheet-menuButton, .luckysheet-rightgclick-menu, .luckysheet-mousedown-cancel, .luckysheet-filter-menu{
  z-index: 1070;
}